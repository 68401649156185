<template>
    <transition>
        <div ref="contentsWrap" class="container contents-wrap" id="contentsWrap">
            <div class="contents-box page-sub-box clearfix ui-glid-box ">
                <table class="top_table table_form line-bin">
                    <caption>
                        <strong>재직 상태 변경</strong>
                    </caption>
                    <colgroup>
                        <col style="width:130px;">
                        <col style="width:auto;">
                    </colgroup>

                    <thead class="sub_title_txt">
                        <tr>
                            <td colspan="2"><h2>선택 대상</h2></td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <th scope="row">
                                {{ getSelectedUserList }}
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="contents-box page-sub-box clearfix ui-glid-box ">
                <table class="table_form line-bin">
                    <caption>
                        <strong>재직 상태 변경</strong>
                    </caption>
                    <colgroup>
                        <col style="width:100px;">
                        <col style="width:auto;">
                    </colgroup>

                    <!-- <thead class="sub_title_txt">
                        <tr>
                            <td colspan="2">
                                <h2>
                                    인사 정보
                                </h2>
                            </td>
                        </tr>
                    </thead> -->

                    <tbody>
                        <tr>
                            <th scope="row">
                                <label for="label01">
                                    재직 상태
                                    <span class="icon_require">필수항목</span>
                                </label>
                            </th>
                            <td>
                                <DxSelectBox 
                                    placeholder="선택" 
                                    :items="getAppointCode" 
                                    display-expr="codeNm" 
                                    value-expr="id" 
                                    v-model="formData.appointCd"
                                    :styling-mode="config.stylingMode" 
                                    :width="150" 
                                    :height="30" 
                                >
                                    <DxValidator>
                                        <DxRequiredRule message="재직상태는 필수값입니다."/>
                                    </DxValidator>
                                </DxSelectBox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="container-bottom-box">
                선택된 <span class="length-txt">{{ contentData.selectedRowsData.length }}</span>개의 인사정보의 재직상태를 위의 선택한 상태로 변경하시겠습니까?
            </div>
        </div>
    </transition>
</template>

<script>
    import { DxTooltip } from 'devextreme-vue/tooltip';
    import { DxPopover } from 'devextreme-vue/popover';
    import { DxDataGrid, DxSelection, DxScrolling, DxColumn ,DxFilterRow, DxOperationDescriptions, DxFilterPanel, DxHeaderFilter } from 'devextreme-vue/data-grid';
    import { DxTreeList } from 'devextreme-vue/tree-list';
    import CheckBox from "devextreme/ui/check_box";
    import { DxTextBox } from 'devextreme-vue/text-box';
    //import { DxButton } from 'devextreme-vue/button';
    
    import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
    import DxSwitch from 'devextreme-vue/switch';
    import { DxSelectBox } from 'devextreme-vue/select-box';

    import { isSuccess } from "@/plugins/common-lib";

    export default {
        components: {
            DxTooltip,
            DxPopover,
            DxDataGrid,
            DxTreeList,
            DxSelection,
            DxScrolling,
            DxColumn,
            DxFilterRow,
            DxOperationDescriptions,
            DxFilterPanel,
            DxHeaderFilter,
            CheckBox,
            DxTextBox,
            //DxButton,

            DxValidator, 
            DxRequiredRule,
            DxSwitch,
            DxSelectBox

        },
        props:{
            contentData: Object,
            iconData: Array,
        },
        watch: {
        },
        data() {
            return {
                config:{
                    stylingMode: 'outlined',
                    authMenuList : [],              //메뉴권한 selectbox data list
                    isTooltip: {                    //tooltip 관련
                        memberInfo: false,
                        menuInfo: false,
                    },
                }, 
                formData: {
                    id: null,
                    appointCd : null,
                    editId: this.$store.getters.getLoginId,
                },
                //메뉴 접근 관련 데이터
                menuDatas: {
                    recursive: true,
                    mode: 'multiple',
                    defaultDatas: null,             //메뉴접근 TreeList default data
                    lastChildrenDatas : [],         //마지막 뎁스의 children 데이터 리스트
                    selectedDatas:[],               //메뉴접근 TreeList selectedData(선택 이벤트를 사용시 선택되는 데이터)
                    selectedIds : [],               //메뉴접근 TreeList selected menu id(DB에 저장된 데이터를 출력하기 위해)
                },
            }
        },
        computed: {
            /** @description: props 리스트에서 선택한 데이터 */
            selectedRowsData(){
                return this.contentData.selectedRowsData;
            },
            /** @description: 선택 대상 사번 리스트 출력 */
            getSelectedUserList(){
                let userNoList = '';
                this.selectedRowsData.forEach((d, idx) => {
                    userNoList += `${d.userNo} ( ${d.userNm} )`;
                    if( idx < this.selectedRowsData.length - 1 ){
                        userNoList += ', ';
                    }
                });

                return userNoList;
            },
            /** @description: props 리스트에서 선택한 데이터 */
            getApiActionNm(){
                return this.contentData.apiActionNm;
            },
            /** @description: 재직 코드 id 값 가져오기 */
            getAppointCode(){
                return this.$_getCode('appoint');
            },
        },
        methods: {
            
            /** @description : 초기 데이터 promise all로 가져오기 */
            async callPromiseAllInitData(){
                
            },
            
            /** @description : 라이프사이클 creaed시 호출되는 메서드 */
            createdData(){

                //초기 데이터 호출
                //this.callPromiseAllInitData();

            },
            /** @description : 라이프사이클 mounted시 호출되는 메서드 */
            mountedData(){
                this.$_eventbus.$on('ModalChangeState:onSaveData', async(e, resolve) => {
                    if( !e.validationGroup.validate().isValid )
                    return;

                    if( this.contentData.selectedRowsData.filter(d => d.appointCd === this.formData.appointCd).length > 0 ){
                        return this.$_Msg(this.$_msgContents('EXIST_PERSONAL'));
                    }

                    let formDatas = this.contentData.selectedRowsData.map(d => {
                        let id = d.id;
                        return { id };
                    });

                    if( await this.$_Confirm('재직상태를 일괄 변경 하시겠습니까?') ){
                        let data = {
                            data: {
                                user: formDatas,
                                state: this.formData.appointCd,
                            }
                        };
                        let payload = {
                            actionname: 'APPOINT_USER_LIST_UPDATE',
                            data: data,
                            loading: true,
                        };

                        let res = await this.CALL_API(payload);
                        if( isSuccess(res) ) {
                            this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE'));
                            resolve({status: 200});
                        }
                    }
                });
            },
            /** @description : 라이프사이클 destroyed시 호출되는 메서드 */
            destroyedData(){
                this.$_eventbus.$off('ModalChangeState:onSaveData');
            },
        },
        created() {
            this.createdData();
        },
        mounted() {
            this.mountedData();
        },
        destroyed() {
            this.destroyedData();
        },
    }
</script>

<style scoped>
    .page-sub-box { padding: 0 0px; }
    .table_form td > div { display: inline-block; vertical-align: middle; }
    .ui-glid-box > div.fl, .ui-glid-box > div.fr { border-right: 0; }
    .ui-glid-box .table_form tbody tr > th { min-width: 120px; }
    .contents-box .top_table .sub_title_txt tr td { padding: 10px 0 10px 15px; }
    .contents-box .top_table th { padding: 5px 0 15px 15px; font-size: 0.9em; }
    .contents-box .fl .sub_title_txt tr td { padding: 10px 0 10px 15px; }
    .contents-box .fr .sub_title_txt tr td { padding: 10px 0 10px 20px; }
    .container-bottom-box { margin-top: 20px; font-size: 0.9em; text-align: center; }
    .container-bottom-box .length-txt { color: tomato; }
</style>
<style >
    

</style>